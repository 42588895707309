<script lang="ts">
    import type { StoresKey } from "~/stores";
    import { getStoresContext } from "~/stores";
    import { onMount, onDestroy } from 'svelte';
    import Swiper from "swiper";
    import { Navigation, Pagination } from "swiper/modules";
    import { generateRandomID } from "~/util/common";

    export let request_store_key: StoresKey;
    export const fragment: Fragment | undefined = undefined;
    export let preloadedData: {
        title: string;
        subtitle: string;
        images: string[];
    };
    export let config: {
        showButton: boolean;
        leftImageAlignment: boolean;
        displayExternalWatchMoreButton: boolean;
        backgroundColor: string;
        buttonBackgroundColor: string;
        buttonFontColor: string;
        textFontColor: string;
        buttonHref: string;
        moreNewsHref: string;
        buttonText: string;
    };

    const { T } = getStoresContext(request_store_key);
    $: t = $T;
    
    const { title, subtitle, images } = preloadedData;
    const uniqueId = generateRandomID();
    
    let swiperEl: HTMLElement;
    let swiperInstance: Swiper | null = null;
    let isBeginning = true;
    let isEnd = false;

    const shouldInitializeSwiper = images.length > 1;

    $: buttonText = config.buttonText || t`see-more`;

    const initializeSwiper = () => {
        if (!shouldInitializeSwiper || !swiperEl || swiperInstance) return;
        
        swiperInstance = new Swiper(swiperEl, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 0,
            pagination: {
                enabled: true,
                el: `.pagination-${uniqueId}`,
                clickable: true
            },
            watchOverflow: true,
            observer: true,
            observeParents: true,
            on: {
                slideChange: () => {
                    if (swiperInstance) {
                        isBeginning = swiperInstance.isBeginning;
                        isEnd = swiperInstance.isEnd;
                    }
                }
            }
        });
    };

    onMount(() => {
        if (shouldInitializeSwiper) {
            initializeSwiper();
        }
        return () => {
            if (swiperInstance) {
                swiperInstance.destroy(true, true);
                swiperInstance = null;
            }
        };
    });

    const handlePrevClick = (e: MouseEvent) => {
        e.stopPropagation();
        if (swiperInstance) swiperInstance.slidePrev();
    };

    const handleNextClick = (e: MouseEvent) => {
        e.stopPropagation();
        if (swiperInstance) swiperInstance.slideNext();
    };
</script>

<div class="ainoa-news-events-lift">
    <div class="grid-container {config.leftImageAlignment ? 'left' : 'right'}">
        <div class="images">
            {#if shouldInitializeSwiper}
                <div class="swiper" bind:this={swiperEl}>
                    <div class="swiper-wrapper">
                        {#each images as image}
                            <div class="swiper-slide">
                                <img src={image} alt="" class="carousel-image" />
                            </div>
                        {/each}
                    </div>
                    <button 
                        class="navigation-button prev"
                        on:click={handlePrevClick}
                        class:disabled={isBeginning}
                        disabled={isBeginning}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M16 10H4M4 10L10 4M4 10L10 16" stroke="var(--color-brand-primary)" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </button>
                    <button 
                        class="navigation-button next"
                        on:click={handleNextClick}
                        class:disabled={isEnd}
                        disabled={isEnd}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M4 10H16M16 10L10 4M16 10L10 16" stroke="var(--color-brand-primary)" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </button>
                    <div class="pagination pagination-{uniqueId}" />
                </div>
            {:else}
                <div class="single-image">
                    <img src={images[0]} alt="" class="carousel-image" />
                </div>
            {/if}
        </div>
        <div class="description" style="--background-color: {config.backgroundColor}; --lift-font-color: {config.textFontColor}; --button-background: {config.buttonBackgroundColor}; --button-font-color: {config.buttonFontColor};">
            <h2 class="title">{title}</h2>
            <div class="subtitle">{@html subtitle}</div>
            {#if config.showButton}
                <a href={config.buttonHref} class="events-button">{buttonText}</a>
            {/if}
        </div>
    </div>
    {#if config.displayExternalWatchMoreButton && config.moreNewsHref}
        <div class="external-button-container">
            <a href={config.moreNewsHref} class="external-button">
                {t`see-more`}
            </a>
        </div>
    {/if}
</div>

<style lang="scss">
    :global(.ainoa-news-events-lift) {
        width: 100%;
        overflow: hidden;
        
        .grid-container {
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 640px;
            max-height: 640px;
            contain: content;
            
            &.right {
                .images { order: 2; }
                .description { order: 1; }
            }
            
            &.left {
                .images { order: 1; }
                .description { order: 2; }
            }

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
                height: auto;
                max-height: none;
                
                &.left, &.right {
                    .images {
                        order: 1;
                        height: 320px;
                        max-height: 320px;
                    }
                    .description {
                        order: 2;
                        height: auto;
                        min-height: 320px;
                    }
                }
            }
        }

        .images {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            contain: strict;

            @media (max-width: 768px) {
                height: 320px;
                max-height: 320px;
            }

            .single-image {
                height: 100%;
                width: 100%;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .swiper {
            height: 100%;
        }

        .swiper-wrapper {
            height: 100%;
        }

        .swiper-slide {
            height: 100%;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .description {
            position: relative;
            width: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 96px;
            text-align: center;
            background-color: var(--background-color);
            height: 100%;
            
            @media (max-width: 991px) {
                padding: 48px 36px;
            }
            
            .title {
                font-size: 48px;
                font-weight: 600;
                color: var(--lift-font-color);
                margin-bottom: 24px;
            }
            
            .subtitle {
                font-size: 18px;
                line-height: 1.5;
                color: var(--lift-font-color);
                margin-bottom: 48px;
            }
            
            .events-button {
                display: inline-block;
                background-color: var(--button-background);
                color: var(--button-font-color);
                padding: 16px 32px;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 500;
                transition: all 0.3s ease;
                border: 1px solid transparent;
                
                &:hover {
                    background-color: var(--button-font-color);
                    color: var(--button-background);
                    border-color: var(--button-background);
                }
            }
        }

        .navigation-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background: white;
            border: none;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            padding: 0;
            z-index: 1;

            &.prev { left: 20px; }
            &.next { right: 20px; }

            &.disabled {
                opacity: 0.4;
                cursor: not-allowed;
            }

            svg {
                display: block;
            }
        }

        .pagination {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            gap: 8px;
            z-index: 2;
        }
        :global(.swiper-pagination-bullet) {
            width: 10px;
            height: 10px;
            background: #fff;
            opacity: 1;
            border-radius: 50%;
            margin: 0;
        }

        :global(.swiper-pagination-bullet-active) {
            background: var(--color-brand-primary);
        }

        .external-button-container {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 32px 0;
        }

        .external-button {
            display: inline-block;
            background-color: var(--color-brand-primary);
            color: var(--color-accent-white);
            padding: 16px 32px;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.3s ease;
            border: 1px solid transparent;
            &:hover {
                background-color: var(--color-accent-white);
                color: var(--color-brand-primary);
                border: 1px solid var(--color-brand-primary);
            }
        }
    }
</style> 